import React from "react";
import { Link, graphql } from "gatsby";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";

import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import DarkOverlay from "../components/global/darkOverlay";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/pagination";
import PortableText from "../components/portableText/portableText";

export const query = graphql`
  query HomePageQuery {
    homePage: sanityHomePage {
      sectionOneTitle
      sectionOneText
      sectionOnePosts {
        id
        mainImage {
          asset {
            url
          }
          alt
        }
        title
        slug {
          current
        }
        _rawExcerpt
        categories {
          title
        }
      }
      sectionTwoTitle
      _rawSectionTwoText
      sectionTwoImages {
        asset {
          url
        }
        alt
      }
      sectionThreeTitle
      sectionThreeText
      sectionThreeInitiatives {
        mainImage {
          asset {
            url
          }
          alt
        }
        title
        slug {
          current
        }
        _rawExcerpt
      }
      sectionFourTitle
      sectionFourText
      sectionFourNewsLinks {
        newsSourceImg {
          asset {
            url
          }
        }
        newsSourceLink
        newsSource
      }
      sectionFiveTitle
      sectionFiveText
    }
  }
`;

function BannerSection(props) {
  const bannerPosts = props.bannerPosts || [];
  const swiperTimer = 15000;

  return (
    <>
      <div className="banner">
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={{ delay: swiperTimer, disableOnInteraction: false }}
        >
          {bannerPosts.map((post) => (
            <div key={post.id}>
              <SwiperSlide>
                <DarkOverlay>
                  <img
                    src={post.mainImage.asset.url}
                    alt={post.mainImage.alt}
                    className="banner-img"
                  />
                </DarkOverlay>
                <div className="banner-text">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-lg-6 pe-3">
                        <h3 className="banner-title pe-3">{post.title}</h3>
                        <hr />
                        <div className="banner-excerpt d-block d-md-none">
                          <PortableText blocks={post._rawExcerpt} />
                        </div>
                        <Link className="banner-btn" to="">
                          Read More
                        </Link>{" "}
                        {post.categories && <i className="bi bi-dot mx-2"></i>}
                        <span className="banner-category">
                          {post.categories && post.categories[0].title}
                        </span>
                      </div>
                      <div className="col-12 col-lg-6 ps-3 py-2">
                        <div className="banner-excerpt d-none d-md-block">
                          <PortableText blocks={post._rawExcerpt} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>

      <style jsx>
        {`
          .banner {
            position: relative;
          }

          .banner-img {
            width: 100%;
            height: 32rem;
            object-fit: cover;
          }

          .banner-text {
            position: absolute;
            padding: 3rem 0rem;
            width: 100%;
            bottom: 0;
            left: 0;
          }

          .banner-text {
            color: #ffffff;
          }

          .banner-text .banner-title {
            font-size: 1.875rem;
            font-weight: 500;
          }

          .banner-text .banner-excerpt {
            font-weight: 300;
            font-size: 0.875rem;
          }

          .banner-text .banner-btn {
            color: #ececec;
            font-size: 0.875rem;
          }

          .banner-text .banner-btn:hover {
            color: #ffffff;
          }

          .banner-text .banner-category {
            font-size: 0.875rem;
            color: #ececec;
          }

          @media only screen and (max-width: 768px) {
            .banner-img {
              width: 100%;
              height: 43rem;
              object-fit: cover;
            }

            .banner-text .banner-excerpt p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* number of lines to show */
              -webkit-box-orient: vertical;
            }
          }
        `}
      </style>
    </>
  );
}

function SectionOne(props) {
  return (
    <>
      <div className="section-one">
        <div className="container">
          <h1 className="section-one-title">{props.title}</h1>
          <p className="section-one-text my-4">{props.text}</p>
          <div className="section-one-btns">
            <i className="bi bi-chevron-double-down"></i>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .section-one {
            margin: 8.5rem 0rem;
          }

          .section-one .section-one-title {
            font-size: 3.5rem;
            font-weight: 600;
          }

          .section-one .section-one-text {
            color: #505050;
          }

          @media only screen and (max-width: 768px) {
            .section-one .section-one-title {
              font-size: 2.5rem;
            }
          }
        `}
      </style>
    </>
  );
}

function SectionTwo(props) {
  const imgOneUrl = props.imgs[0].asset.url || "",
    imgTwoUrl = props.imgs[1].asset.url || "",
    imgThreeUrl = props.imgs[2].asset.url || "",
    imgFourUrl = props.imgs[3].asset.url || "";

  const imgOneAlt = props.imgs[0].alt || "",
    imgTwoAlt = props.imgs[1].alt || "",
    imgThreeAlt = props.imgs[2].alt || "",
    imgFourAlt = props.imgs[3].alt || "";

  return (
    <>
      <div className="section-two">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="section-two-imgs">
                <div className="img-1">
                  <img src={imgOneUrl} alt={imgOneAlt} />
                </div>
                <div className="img-2">
                  <img src={imgTwoUrl} alt={imgTwoAlt} />
                </div>
                <div className="img-3">
                  <img src={imgThreeUrl} alt={imgThreeAlt} />
                </div>
                <div className="img-4">
                  <img src={imgFourUrl} alt={imgFourAlt} />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 py-5">
              <h2 className="section-two-title mb-5">{props.title}</h2>
              <div className="pe-0 pe-md-5">
                <PortableText blocks={props.body} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .section-two-title {
            font-size: 3.2rem;
            color: #505050;
          }

          .section-two-imgs {
            width: 85%;
            margin: auto;
            position: relative;
            width: 100%;
            min-height: 38rem;
          }

          .section-two-imgs .img-1,
          .section-two-imgs .img-2,
          .section-two-imgs .img-3,
          .section-two-imgs .img-4 {
            position: absolute;
          }

          .section-two-imgs .img-1 {
            width: 30%;
            height: 15rem;
            left: 48%;
            transform: translate(-100%, 0px);
          }

          .section-two-imgs .img-2 {
            top: 4rem;
            right: 48%;
            width: 35%;
            height: 16rem;
            transform: translate(100%, 0px);
          }

          .section-two-imgs .img-3 {
            top: 17rem;
            width: 40%;
            height: 14rem;
            left: 48%;
            transform: translate(-100%, 0px);
          }

          .section-two-imgs .img-4 {
            top: 22rem;
            right: 48%;
            width: 30%;
            height: 14rem;
            transform: translate(100%, 0px);
          }

          .section-two-imgs img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 7px;
          }

          @media only screen and (max-width: 768px) {
            .section-two-title {
              font-size: 2.5rem;
            }

            .section-two-imgs {
              min-height: 35rem;
            }

            .section-two-imgs .img-1 {
              width: 40%;
              height: 13rem;
            }

            .section-two-imgs .img-2 {
              top: 4rem;
              width: 45%;
              height: 12rem;
            }

            .section-two-imgs .img-3 {
              top: 14rem;
              width: 45%;
              height: 14rem;
            }

            .section-two-imgs .img-4 {
              top: 17rem;
              width: 37%;
              height: 14rem;
            }
          }
        `}
      </style>
    </>
  );
}

function InitiativesSection(props) {
  const initiatives = props.initiatives;

  return (
    <>
      <div className="initiatives-section">
        <div className="container">
          <h2>{props.title}</h2>
          <p className="mb-4">{props.text}</p>
          <div className="position-relative">
            <div className="swiper-btns justify-content-between d-none d-md-flex">
              <a
                className="swiper-button-prev position-relative"
                style={{ left: "-1.85rem" }}
              >
                <i className="bi bi-chevron-left"></i>
              </a>
              <a
                className="swiper-button-next position-relative"
                style={{ right: "-1.85rem" }}
              >
                <i className="bi bi-chevron-right"></i>
              </a>
            </div>
          </div>
          <Swiper
            modules={[Pagination, Navigation]}
            slidesPerView={1}
            spaceBetween={15}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
            }}
          >
            {initiatives.map((initiative) => (
              <div key={initiative.id}>
                <SwiperSlide className="pt-3 pb-5">
                  <Link to={`initiatives/${initiative.slug.current}`}>
                    <img
                      src={initiative.mainImage.asset.url}
                      alt={initiative.mainImage.alt}
                      className="initiative-img my-2"
                    />
                    <h3 className="initiative-title my-2 text-light">
                      {initiative.title}
                    </h3>
                    <div className="initiative-text my-2">
                      <PortableText blocks={initiative._rawExcerpt} />
                    </div>
                  </Link>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>
      </div>

      <style jsx>
        {`
          .initiatives-section {
            margin: 5rem 0rem;
            background-color: #000000;
            color: #ffffff;
            padding: 2.5rem 0rem;
          }

          .initiatives-section p {
            color: #cecece;
            font-weight: 300;
          }

          .initiative-img {
            width: 100%;
            height: 17rem;
            object-fit: cover;
          }

          .initiatives-section .initiative-title {
            font-weight: 300;
          }

          .initiative-text {
            font-size: 0.875rem;
          }

          .initiatives-section .swiper-pagination-bullet {
            background-color: #ffffff;
          }

          .initiatives-section .swiper-pagination-bullet-active {
            background-color: #ffffff;
          }

          .initiatives-section .swiper-btns {
            position: absolute;
            width: 100%;
            top: 12rem;
            left: 0rem;
          }

          .initiatives-section .swiper-btns a {
            color: #a1a1a1;
            font-size: 1.25rem;
            cursor: pointer;
          }

          .initiatives-section .swiper-btns a:hover {
            color: #ffffff;
          }
        `}
      </style>
    </>
  );
}

function NewsLink(props) {
  return (
    <>
      <div className="news-link mx-2 mx-md-4 my-4">
        <a href={props.linkURL} rel="opener noopener" _target="blank">
          <img src={props.newsImg} alt={props.newsImgAlt} />
        </a>
      </div>

      <style jsx>
        {`
          .news-link {
            height: 2rem;
          }

          .news-link a img {
            height: 2rem;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            opacity: 0.7;
            transition: 0.4s all;
          }

          .news-link a img:hover {
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
            opacity: 1;
            transition: 0.4s all;
          }

          @media only screen and (max-width: 768px) {
            .news-link a img {
              height: 1.8rem;
            }
          }
        `}
      </style>
    </>
  );
}

function NewsSection(props) {
  const newsLinks = props.newsLinks;

  return (
    <>
      <div className="news-section py-5">
        <div className="container">
          <h2 className="text-center">{props.title}</h2>
          <p className="text-center">{props.text}</p>

          <div className="d-flex justify-content-center flex-wrap my-5">
            {newsLinks.map((newsLink) => (
              <NewsLink
                key={newsLink.newsSource}
                linkURL={newsLink.newsSourceLink}
                newsImg={newsLink.newsSourceImg.asset.url}
                newsImgAlt={newsLink.newsSource}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function GetInvolvedSection(props) {
  return (
    <>
      <div className="get-involved py-5 my-5">
        <div className="container">
          <div className="text-center">
            <h2 className="text-light mb-5">{props.title}</h2>
            <p className="text-light w-75 mx-auto mb-4">{props.text}</p>
            <hr className="text-light my-5" />
            <div className="btns d-flex my-4 justify-content-center">
              <Link to="/donate" className="btn btn-light mx-3 px-4 py-2">
                Donate
              </Link>
              <Link to="/contact/" className="btn btn-outline-light px-4 py-2">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .get-involved {
            background-color: #000000;
          }

          .get-involved h2 {
            font-size: 2.1rem;
          }
        `}
      </style>
    </>
  );
}

const Index = ({ data }) => {
  return (
    <>
      <SEO />
      <Layout>
        <BannerSection bannerPosts={data.homePage.sectionOnePosts} />
        <SectionOne
          title={data.homePage.sectionOneTitle}
          text={data.homePage.sectionOneText}
        />
        <SectionTwo
          title={data.homePage.sectionTwoTitle}
          body={data.homePage._rawSectionTwoText}
          imgs={data.homePage.sectionTwoImages}
        />
        <InitiativesSection
          initiatives={data.homePage.sectionThreeInitiatives}
          title={data.homePage.sectionThreeTitle}
          text={data.homePage.sectionThreeText}
        />
        <NewsSection
          title={data.homePage.sectionFourTitle}
          text={data.homePage.sectionFourText}
          newsLinks={data.homePage.sectionFourNewsLinks}
        />

        <GetInvolvedSection
          title={data.homePage.sectionFiveTitle}
          text={data.homePage.sectionFiveText}
        />
      </Layout>
    </>
  );
};

export default Index;
