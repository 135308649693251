import React from "react";

const DarkOverlay = (props) => {
  return (
    <>
      <div className="dark-overlay">
        {props.children}
        <div className="dark-overlay-inner"></div>
      </div>

      <style jsx>{`
        .dark-overlay {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        .dark-overlay .dark-overlay-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.95) 0%,
              rgba(0, 0, 0, 0.82) 15.8%,
              rgba(0, 0, 0, 0) 35.4%,
              rgba(0, 0, 0, 0) 40.69%,
              rgba(0, 0, 0, 0.82) 78.16%,
              #000000 95%
            )
            #ffffff00;
          z-index: 1;
        }

        @media only screen and (max-width: 768px) {
          .dark-overlay .dark-overlay-inner {
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.95) 0%,
                rgba(0, 0, 0, 0.82) 15.8%,
                rgba(0, 0, 0, 0) 35.4%,
                rgba(0, 0, 0, 0) 56.69%,
                rgba(0, 0, 0, 0.82) 70%,
                #000000 95%
              )
              #ffffff00;
          }
        }
      `}</style>
    </>
  );
};

export default DarkOverlay;
